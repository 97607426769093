import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  createBrowserRouter,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import appRouter from "./appRouter";
import adminRouter from "./adminRouter";
import useCustomLogin from "../hooks/useCustomLogin";

const Loading = () => (
  <div className="flex justify-center items-center h-screen w-full">
    <div className="animate-spin border-t-2 border-b-2 border-blue-500 rounded-full w-8 h-8"></div>
    <span className="ml-2">Loading...</span>
  </div>
);

const NotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/", { replace: true });
  }, [navigate]);
};

const Login = lazy(() => import("../pages/LoginPage"));
const Guide = lazy(() => import("../pages/GuidePage"));
const AppIndex = lazy(() => import("../pages/app/AppIndex"));
const AdminIndex = lazy(() => import("../pages/admin/AdminIndex"));

const getDashboardPath = (roleNames) => {
  if (roleNames.includes("SUPER") || roleNames.includes("ADMIN")) {
    return "/admin/";
  }
  return "/app/";
};

const RequireAuth = ({ children, isAdminRoute }) => {
  const { isLogin, loginInfo, refreshToken, doLogout } = useCustomLogin();
  const location = useLocation();
  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  useEffect(() => {
    const checkAuth = async () => {
      if (!isLogin) {
        // 새로고침 후 리프레시 토큰으로 액세스 토큰을 갱신 시도
        const refreshed = await refreshToken();
        if (!refreshed) {
          // 리프레시 실패 시 로그아웃 처리
          doLogout();
        }
      }
      // 로그인 상태가 갱신되었거나, 리프레시 토큰이 실패한 경우 로딩을 종료
      setLoading(false);
    };
    checkAuth();
  }, [isLogin, refreshToken, doLogout]);

  // 로딩 중일 때는 아무 화면도 렌더링하지 않음 (스피너 표시)
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen w-full">
        <div className="animate-spin border-t-2 border-b-2 border-blue-500 rounded-full w-8 h-8"></div>
        <span className="ml-2">Loading...</span>
      </div>
    );
  }

  // 로그인 상태가 아니거나 로그인 정보가 없으면 로그인 페이지로 이동
  if (!isLogin || !loginInfo) {
    // 현재 경로가 "/app"으로 시작하는 경우에만 returnUri를 추가
    if (window.location.pathname.startsWith("/app")) {
      const currentPath = encodeURIComponent(
        window.location.pathname + window.location.search
      );
      window.location.href = `/login?returnUri=${currentPath}`;
    } else {
      // "/app"이 아닌 경우에는 일반적으로 로그인 페이지로 이동
      window.location.href = "/login";
    }
    return null; // 컴포넌트 렌더링을 중단합니다.
  }

  // 관리자 경로일 때 추가적인 권한 체크
  if (isAdminRoute) {
    const allowedRoles = ["SUPER", "ADMIN", "MANAGER"];
    const userRoles = loginInfo.roleNames;

    const hasAccess = userRoles.some((role) => allowedRoles.includes(role));

    if (!hasAccess) {
      const dashboardPath = getDashboardPath(userRoles);
      return <Navigate to={dashboardPath} state={{ from: location }} replace />;
    }
  }

  // 메인 경로에서 대시보드로 이동
  const dashboardPath = getDashboardPath(loginInfo.roleNames);
  if (location.pathname === "/") {
    return <Navigate to={dashboardPath} state={{ from: location }} replace />;
  }

  // 모든 조건이 충족되면 children 렌더링
  return children;
};

const InitLoginPage = ({ children }) => {
  const { initLogin } = useCustomLogin();
  useEffect(() => {
    initLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return children;
};

const root = createBrowserRouter(
  [
    {
      path: "login",
      element: (
        <InitLoginPage>
          <Suspense fallback={<Loading />}>
            <Login />
          </Suspense>
        </InitLoginPage>
      ),
    },
    {
      path: "/",
      element: (
        <RequireAuth>
          <Suspense fallback={<Loading />}>
            <Navigate to="/app/performance/dashboard" replace />
          </Suspense>
        </RequireAuth>
      ),
    },
    {
      path: "app",
      element: (
        <RequireAuth isAdminRoute={false}>
          <Suspense fallback={<Loading />}>
            <AppIndex />
          </Suspense>
        </RequireAuth>
      ),
      children: appRouter(),
    },
    {
      path: "admin",
      element: (
        <RequireAuth isAdminRoute={true}>
          <Suspense fallback={<Loading />}>
            <AdminIndex />
          </Suspense>
        </RequireAuth>
      ),
      children: adminRouter(),
    },
    {
      path: "guide",
      element: <Guide />, // 가이드 페이지
    },
    {
      path: "*", // 모든 잘못된 경로 처리
      element: <NotFound />, // 404 페이지 렌더링
    },
  ],
  {
    // future 플래그 설정
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

export default root;
